import $ from "jquery";

export async function getAllCohorts() {
  let url = '/api/cohorts/';
  try {
    let res = await fetch(url);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}




export function postCohort(cohort_code, cohort_name){
  $.ajax({
    url: "/api/cohorts/"+cohort_code+"/"+cohort_name,
    type: 'POST',
    dataType: 'json', // added data type
    success: function(res) {
      console.log("success");
    }
  });
}
