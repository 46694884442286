import $ from "jquery";


export function downloadContract(cohort, type, fileName) {
  $.ajax({
    url: "/api/templates/" + cohort + "/" + type,
    type: 'GET',
    dataType: 'json', // added data type
    success: function(res) {
      downloadBase64File(res[0][0].document, fileName+".docx");
    }
  });
}



export function postTemplate(cohort_code, contract_type, document){
  $.ajax({
    url: "/api/templates",
    type: "POST",
    data: {
      'cohort_code': cohort_code,
      'type': contract_type,
      'document': document
    },
    success: function(data){
      console.log("success");
    }
  });

}

export function putTemplate(cohort_code, contract_type, document){
  $.ajax({
    url: "/api/templates",
    type: "PUT",
    data: {
      'cohort_code': cohort_code,
      'type': contract_type,
      'document': document
    },
    success: function(data){
      console.log("success");
    }
  });
}

export function downloadBase64File(contentBase64, fileName) {
  const linkSource = `data:application/docx;base64,${contentBase64}`;
  const downloadLink = document.createElement('a');
  document.body.appendChild(downloadLink);

  downloadLink.href = linkSource;
  downloadLink.target = '_self';
  downloadLink.download = fileName;
  downloadLink.click();
}


export async function getContract(cohort, type) {
  let url = '/api/templates/'+cohort+'/'+type;
  try {

    let res = await fetch(url);
    return await res.json();
  } catch (error) {
    console.log(error);
  }
}


export function deleteCohortFromDB(cohort_code){

  $.ajax({
    url: "/api/cohorts/"+cohort_code,
    type: "DELETE",
    success: function(data){
      console.log("success");
    }
  });
  $.ajax({
    url: "/api/templates/"+cohort_code,
    type: "DELETE",
    success: function(data){
      console.log("success");
    }
  });

}


